@import url(https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.0.3/tailwind.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.user-info .ant-card-meta-title,
.ant-card-meta-description {
  color: #e7e7e7;
}

.slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel .slick-dots li button {
  color: black;
  font-size: 16px;
}

.empty-question-box {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  color: #d9d9d9;
  transition: all 0.3s;
}

.empty-question-box:hover {
  color: #bbbbbb;
  border-color: #40a9ff;
}

.question-image-list .ant-form-item-control-input-content>span {
  display: flex;
  flex-direction: column-reverse;
}

.question-image-list .ant-form-item-control-input-content>span .ant-upload-list-item {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.question-action {
  height: 100%;
  width: 100%;
  color: #00000073;
}

.question-action:hover {
  color: #40a9ff;
  background-color: #fafafa;
}

.question-action:focus {
  color: #00000073;
  background-color: #fafafa;
}

.question-answers .ant-form-item {
  margin-bottom: 8px;
}

.empty-session-box {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  color: #d9d9d9;
  transition: all 0.3s;
}

.empty-session-box:hover {
  color: #bbbbbb;
  border-color: #40a9ff;
}

.session-image-list .ant-form-item-control-input-content>span {
  display: flex;
  flex-direction: column-reverse;
}

.session-image-list .ant-form-item-control-input-content>span .ant-upload-list-item {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.session-action {
  height: 100%;
  width: 100%;
  color: #00000073;
}

.session-action:hover {
  color: #40a9ff;
  background-color: #fafafa;
}

.session-action:focus {
  color: #00000073;
  background-color: #fafafa;
}

.session-answers .ant-form-item {
  margin-bottom: 8px;
}

.ant-typography-edit-content {
  left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100%;
}

.class-name .ant-typography-edit-content {
  margin-right: 6px;
}

.class-cover-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.cover-edit>.ant-upload {
  width: 200px;
  height: 200px;
}

.session-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 12px;
}

.session-video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.discussion-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 12px;
}

.discussion-video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.attachment-file-list .ant-form-item-control-input-content>span {
  display: flex;
  flex-direction: column-reverse;
}

.attachment-file-list .ant-form-item-control-input-content>span .ant-upload-list-item {
  margin-top: 0px !important;
  margin-bottom: 8px;
}

.attachment-file-list .ant-upload.ant-upload-drag.ant-upload-disabled {
  display: none !important;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: inline !important;
  display: initial !important;
  vertical-align: baseline !important;
  vertical-align: initial !important;
}
